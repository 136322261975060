<template>
  <div class="page">
    <Head />
    <Sidebar />
    <div class="body-main">
      <breadInfo />
      <div class="container bg flex-1 flex-v">
        <div class="flex-1 flex-v p025">
          <div class="table flex-1">
            <el-tabs v-model="activeName">
              <el-tab-pane label="设备" name="first">
                <span slot="label" class="cur">设备</span>
                <div class="top-bar">
                  <button class="ann annbg2" @click="onAdd()">添加设备</button>
                </div>
                <el-table
                  :data="tableData"
                  height="60vh"
                  v-loading="loading"
                  element-loading-background="rgba(0, 0, 0, 0.8)"
                >
                  <el-table-column prop="id" width="60" label="ID">
                  </el-table-column>
                  <el-table-column prop="name" label="名称"> </el-table-column>
                  <el-table-column
                    :formatter="formatFreeShippingInfo"
                    prop="huiZongFlag"
                    label="能耗参与汇总"
                  >
                  </el-table-column>
                  <el-table-column prop="brand" label="品牌"> </el-table-column>
                  <el-table-column prop="productionDate" label="出厂日期">
                  </el-table-column>
                  <el-table-column prop="model" label="型号"> </el-table-column>
                  <el-table-column prop="powerCategoryName" label="设备类型">
                  </el-table-column>
                  <el-table-column prop="power" label="功率"> </el-table-column>
                  <el-table-column prop="otherMsg" label="其他设备信息">
                  </el-table-column>
                  <el-table-column prop="ecpectTime" label="预计工作寿命">
                  </el-table-column>
                  <el-table-column prop="alreadyWorkTime" label="已工作时长">
                  </el-table-column>
                  <!-- <el-table-column label="告警配置">
                                <template slot-scope="scope">
                                    <span class="lk c-blue" @click="onEdit(scope.row)">修改</span>
                                </template>
                            </el-table-column> -->
                  <el-table-column label="操作" width="150">
                    <template slot-scope="scope">
                      <span
                        class="lk c-blue"
                        @click="handleEdit(scope.$index, scope.row)"
                        >修改</span
                      >
                      <span
                        class="lk c-red"
                        @click="handleDelete(scope.$index, scope.row)"
                        >删除</span
                      >
                    </template>
                  </el-table-column>
                </el-table>

                <el-pagination
                  @current-change="handleCurrentChange"
                  v-model:current-page="pageInfo.pageNum"
                  layout="prev, pager, next, jumper,total"
                  :total="pageInfo.total"
                  :page-size="pageInfo.pageSize"
                >
                </el-pagination>
              </el-tab-pane>
              <el-tab-pane label="绑定计量表" name="second">
                <div class="gauges-sec flex-1 scrollybg">
                  <div class="flex flex-pack-justify">
                    <div class="col">
                      <div class="mod mod1">
                        <h5 class="smtit">设备</h5>
                        <div class="detail">
                          <el-form ref="form" :model="biaoForm">
                            <el-form-item label="请选择设备：">
                              <el-select
                                v-model="equimentId"
                                placeholder="选择设备"
                                @change="getBindMeters"
                              >
                                <el-option
                                  v-for="item in equiments"
                                  :key="item.value"
                                  :label="item.name"
                                  :value="item.id"
                                />
                              </el-select>
                            </el-form-item>
                          </el-form>
                        </div>
                      </div>
                    </div>
                    <div class="col">
                      <div class="mod mod2">
                        <h5 class="smtit">绑定的计量表</h5>
                        <div class="detail bind">
                          <draggable
                            v-model="meterList"
                            :group="groupBind"
                            animation="300"
                            @add="onAdd"
                            @remove="onRemove"
                            class="drag-content"
                          >
                            <div
                              v-for="(meter, index) in meterList"
                              class="list"
                              :key="index"
                            >
                              <router-link to="" class="lk bgfull">{{
                                meter.gaugesName
                              }}</router-link>
                            </div>
                          </draggable>
                        </div>
                      </div>
                      <div class="mod mod3">
                        <h5 class="smtit">回收站</h5>
                        <div class="detail">
                          <draggable
                            :group="groupDelete"
                            animation="300"
                            :sort="true"
                          >
                            <div class="delete">
                              <draggable
                                :group="groupDelete"
                                animation="300"
                                :sort="true"
                                style="height: 100%; width: 100%"
                              ></draggable>
                              <!-- <img src="../assets/images/set_10.png" alt="" class="icon" /> -->
                            </div>
                          </draggable>
                        </div>
                      </div>
                    </div>
                    <div class="col">
                      <div class="mod mod4">
                        <h5 class="smtit">计量表</h5>
                        <div class="detail all">
                          <div class="mb20">
                            <el-form ref="form" :model="biaoForm2">
                              <el-form-item label="请选择表计类型：">
                                <el-select
                                  v-model="biaoForm2.type"
                                  placeholder="计量表"
                                  @change="getNotBindmeters"
                                >
                                  <el-option
                                    label="计量表"
                                    value="1"
                                  ></el-option>
                                  <el-option
                                    label="传感器"
                                    value="2"
                                  ></el-option>
                                  <el-option
                                    label="虚拟表"
                                    value="3"
                                  ></el-option>
                                  <el-option
                                    label="离线表"
                                    value="4"
                                  ></el-option>
                                </el-select>
                              </el-form-item>
                              <el-form-item label="计量表列表：">
                                <el-input
                                  placeholder="搜索"
                                  v-model="biaoForm2.search"
                                  class=""
                                  @input="handleInput()"
                                >
                                  <el-button
                                    slot="append"
                                    icon="el-icon-search"
                                  ></el-button>
                                </el-input>
                              </el-form-item>
                            </el-form>
                          </div>
                          <draggable
                            v-model="fillist"
                            :group="groupAll"
                            animation="300"
                          >
                            <div
                              v-for="(meter, key) in fillist"
                              class="list"
                              :key="key"
                            >
                              <router-link to="" class="lk bgfull">{{
                                meter.gaugesName
                              }}</router-link>
                            </div>
                          </draggable>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </el-tab-pane>
            </el-tabs>
          </div>
        </div>
      </div>
    </div>

    <!-- 添加设备 -->
    <el-dialog :visible.sync="addPop" width="40%">
      <div class="pop">
        <div class="toptt">
          <span class="backbtn">添加设备</span>
          <i class="el-icon-close close" @click="addPop = false"></i>
          <div class="dix"></div>
        </div>
        <div class="detail">
          <el-form ref="form" :model="addForm" label-width="110px">
            <el-row :gutter="10">
              <el-col :span="16">
                <el-form-item label="名称:">
                  <el-input v-model="addForm.name"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="能耗参与汇总:">
                  <el-switch
                    v-model="addForm.switch"
                    active-color="#9443de"
                    inactive-color="#ddd"
                  >
                  </el-switch>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="10">
              <el-col :span="12">
                <el-form-item label="品牌:">
                  <el-input v-model="addForm.pinpai"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="生产日期:">
                  <el-date-picker
                    v-model="addForm.riqi"
                    type="date"
                    placeholder="选择日期"
                  >
                  </el-date-picker>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="10">
              <el-col :span="12">
                <el-form-item label="设备类型:">
                  <el-select
                    v-model="addForm.leixing"
                    placeholder="选择设备类型"
                  >
                    <el-option
                      v-for="item in energyTypeList"
                      :key="item.id"
                      :label="item.name"
                      :value="item.id"
                    />
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="型号:">
                  <el-input v-model="addForm.xinghao"></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="10">
              <el-col :span="12">
                <el-form-item label="功率:">
                  <el-input v-model="addForm.gonglv"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="预计工作寿命:">
                  <el-input v-model="addForm.shouming"></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="10">
              <el-col :span="24">
                <el-form-item label="其他设备信息:">
                  <el-input
                    v-model="addForm.miaoshu"
                    placeholder="请输入json格式的其他设备信息"
                  ></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="10">
              <el-col :span="24">
                <el-form-item label="设备图像:" prop="pic">
                  <el-upload
                    class="avatar-uploader"
                    :on-exceed="
                      (files, fileList) => handleExceed(files, fileList, 1)
                    "
                    :class="noneBtnDealImg ? 'disUoloadSty' : ''"
                    :action="upload.uploadUrl"
                    :headers="upload.headers"
                    :fileList="fileList"
                    :limit="1"
                    :on-success="
                      (res, file, fileList) =>
                        handleAvatarSuccess(res, file, fileList, 'img')
                    "
                    list-type="picture-card"
                    :before-upload="beforeAvatarUpload"
                  >
                    <i slot="default" class="el-icon-plus"></i>
                    <div slot="file" slot-scope="{ file }">
                      <img
                        class="el-upload-list__item-thumbnail"
                        :src="file.url"
                        alt=""
                      />
                      <span class="el-upload-list__item-actions">
                        <span
                          class="el-upload-list__item-preview"
                          @click="handlePictureCardPreview(file)"
                        >
                          <i class="el-icon-zoom-in"></i>
                        </span>
                        <span
                          v-if="!disabled"
                          class="el-upload-list__item-delete"
                          @click="handleRemove(file, fileList)"
                        >
                          <i class="el-icon-delete"></i>
                        </span>
                      </span>
                    </div>
                  </el-upload>
                </el-form-item>
              </el-col>
            </el-row>

            <div class="btngroup">
              <button class="ann confirm" @click="onSubmit">确定</button>
              <button class="ann back" @click="addPop = false">返回</button>
            </div>
          </el-form>
        </div>
      </div>
    </el-dialog>
    <el-dialog :visible.sync="dialogVisible" modal-append-to-body>
      <div class="pop pop-adduser">
        <div class="toptt">
          <span class="backbtn">图片预览</span>
          <i class="el-icon-close close" @click="dialogVisible = false"></i>
          <div class="dix"></div>
        </div>
        <img width="100%" :src="dialogImageUrl" alt="" />
      </div>
    </el-dialog>
  </div>
</template>

<script>
import Head from "@/components/head.vue";
import Sidebar from "@/components/sidebar.vue";
import breadInfo from "@/components/breadInfo.vue";
import {
  getBindMeterList,
  getNotBindMeterList,
  delBindMeter,
  addBindMeter,
} from "@/api/equip";
import draggable from "vuedraggable";
import { equipList, delEquip, addEquip, editEquip } from "@/api/equip";
import { getEnergyList, getEquipList } from "@/utils/common";
export default {
  data() {
    return {
      removeFlag: false,
      equimentId: "",
      equiments: [],
      biaoForm: {
        type: "",
        equip: "",
      },
      biaoForm2: {
        type: "1",
        search: "",
      },
      chooseInput: "",
      meterList: [],
      bindMeterList: [],
      allMeterList: [],
      fillist: [],
      groupBind: {
        name: "itxst",
        pull: true, // 拖出
        put: true, // 拖入
      },
      groupAll: {
        name: "itxst",
        pull: true, // 拖出
        put: false, // 拖入
      },
      groupDelete: {
        name: "itxst",
        pull: false, // 拖出
        put: true, // 拖入
      },
      editId: -1,
      activeName: "first",
      energyTypeList: [],
      tableData: [],
      pageInfo: {
        total: 0,
        pageSize: 10,
        pageNum: 1,
      },
      loading: false,
      addPop: false,
      addForm: {
        name: "",
        switch: true,
        pinpai: "",
        riqi: "",
        leixing: "",
        xinghao: "",
        gonglv: "",
        pic: "",
        shouming: "",
        miaoshu: "",
      },
      imgUrl: "",
      dialogImageUrl: "",
      dialogVisible: false,
      noneBtnDealImg: false,
      disabled: false,
      fileList: [],
      upload: {
        // 设置上传的请求头部
        headers: {
          token: sessionStorage.getItem("userToken"),
        },
        // 上传的地址
        uploadUrl: "/apis/002-uploadFileForJson",
      },
    };
  },
  components: {
    Head,
    Sidebar,
    breadInfo,
    draggable,
  },
  onAdd() {
    //
    console.log("添加", this.meterList);
  },
  created() {
    this.getEquipList();
    this.equiments = getEquipList();
  },
  mounted() {},
  watch: {},
  methods: {
    //取两个数组的差值
    getArrDifference(arr1, arr2) {
      return arr1.concat(arr2).filter(function (v, i, arr) {
        return arr.indexOf(v) === arr.lastIndexOf(v);
      });
    },
    //绑定计量表
    onAdd(e) {
      let t = this.getArrDifference(this.meterList, this.bindMeterList);
      let param = {
        deviceId: this.equimentId,
        gaugesId: this.getArrDifference(this.meterList, this.bindMeterList)[0]
          .id,
        gaugesName: this.getArrDifference(this.meterList, this.bindMeterList)[0]
          .name,
        type: this.biaoForm2.type,
      };
      addBindMeter(param).then((res) => {
        if (res.data.code == 0) {
          this.getBindMeters();
        } else {
          this.$message.warning(res.data.msg);
        }
      });
    },
    //解绑计量表
    onRemove(e) {
      let t = this.getArrDifference(this.meterList, this.bindMeterList);
      let param = {
        ids: this.getArrDifference(this.meterList, this.bindMeterList)[0].id,
      };
      delBindMeter(param).then((res) => {
        if (res.data.code == 0) {
          this.getBindMeters();
        } else {
          this.$message.warning(res.data.msg);
        }
      });
    },
    //搜索
    handleInput() {
      this.fillist = [];
      for (let i = 0; i < this.allMeterList.length; i++) {
        if (this.allMeterList[i].gaugesName.includes(this.biaoForm2.search)) {
          this.fillist.push(this.allMeterList[i]);
        }
      }
    },
    //获取已绑定的表计列表
    getBindMeters() {
      let param = {
        pageNum: 1,
        pageSize: 999999999,
        deviceId: this.equimentId,
      };
      getBindMeterList(param).then((res) => {
        if (res.data.code == 0) {
          this.meterList = res.data.rows;
          this.bindMeterList = this.meterList;
        } else {
          this.$message.warning(res.data.msg);
        }
      });
      this.getNotBindmeters();
    },
    //获取指定类型的未绑定表计列表
    getNotBindmeters() {
      if (this.equimentId == "") {
        this.$message.warning("请选择设备");
        return;
      }
      let param = {
        pageNum: 1,
        pageSize: 999999999,
        deviceId: this.equimentId,
        type: this.biaoForm2.type,
      };
      getNotBindMeterList(param).then((res) => {
        if (res.data.code == 0) {
          for (let i = 0; i < res.data.rows.length; i++) {
            res.data.rows[i].gaugesName = res.data.rows[i].name;
          }
          this.allMeterList = res.data.rows;
          this.fillist = this.allMeterList;
          this.biaoForm2.search = "";
        } else {
          this.$message.warning(res.data.msg);
        }
      });
    },
    handleExceed(files, fileList, num) {
      this.$message.warning(
        `当前限制选择 ${num} 个文件，本次选择了 ${
          files.length
        } 个文件，共选择了 ${files.length + fileList.length} 个文件`
      );
    },
    beforeAvatarUpload(file) {
      var img = file.name.substring(file.name.lastIndexOf(".") + 1);
      const suffix = img === "jpg";
      const suffix2 = img === "png";
      const suffix3 = img === "jpeg";
      if (!suffix && !suffix2 && !suffix3) {
        this.$message.error("只能上传图片！");
        return false;
      }
      return suffix || suffix2 || suffix3;
    },
    handleAvatarSuccess(res, file, fileList) {
      this.imgUrl = res.rows[0].url;
      this.noneBtnDealImg = fileList.length >= 1;
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    handleRemove(file, fileList) {
      this.dialogImageUrl = "";
      this.fileList = [];
      this.imgUrl = "";
      this.noneBtnDealImg = fileList.length >= 1;
    },
    //判断是否为json
    judgeJson(str) {
      try {
        const toObj = JSON.parse(str); // json字符串转对象
        if (toObj && typeof toObj === "object") {
          return true;
        }
      } catch {}
      return false;
    },
    //信息转换为是否
    formatFreeShippingInfo(row, column) {
      return row.huiZongFlag == "1"
        ? "是"
        : row.huiZongFlag == "0"
        ? "否"
        : row.huiZongFlag;
    },
    handleEdit(index, row) {
      this.addForm.name = row.name;
      this.addForm.pinpai = row.brand;
      row.huiZongFlag == "1"
        ? (this.addForm.switch = true)
        : (this.addForm.switch = false);
      this.addForm.riqi = row.productionDate;
      this.addForm.leixing = row.powerCategoryId;
      this.addForm.xinghao = row.model;
      this.addForm.gonglv = row.power;
      this.addForm.shouming = row.ecpectTime;
      this.addForm.miaoshu = row.otherMsg;
      this.addForm.pic = row.pic;
      this.editId = row.id;
      this.addPop = true;
      this.energyTypeList = getEnergyList();
    },
    handleDelete(index, row) {
      this.$confirm("此操作将永久删除该设备, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.tableData.splice(index, 1);
          this.deleteEquip(row.id);
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消操作",
          });
        });
    },
    handleCurrentChange(val) {
      this.pageInfo.pageNum = val;
      this.getEquipList();
    },
    //添加设备弹窗
    onAdd() {
      this.editId = -1;
      (this.addForm = {
        name: "",
        switch: true,
        pinpai: "",
        riqi: "",
        leixing: "",
        xinghao: "",
        gonglv: "",
        shouming: "",
        miaoshu: "{}",
      }),
        (this.addPop = true);
      this.energyTypeList = getEnergyList();
    },
    //添加/修改设备
    onSubmit() {
      if (this.addForm.name == "") {
        this.$message.warning("请填写设备名");
      } else if (this.addForm.leixing == "") {
        this.$message.warning("请填写设备类型");
      } else if (this.judgeJson(this.addForm.miaoshu) === false) {
        if (this.addForm.miaoshu != "") {
          this.$message.warning("请按json格式正确填写设备的其他信息");
        }
      } else {
        let energyName = "";
        for (let i = 0; i < this.energyTypeList.length; i++) {
          if (this.energyTypeList[i].id == this.addForm.leixing) {
            energyName = this.energyTypeList[i].name;
          }
        }

        let param = {
          brand: this.addForm.pinpai,
          ecpectTime: this.addForm.shouming,
          huiZongFlag: Number(this.addForm.switch),
          model: this.addForm.xinghao,
          name: this.addForm.name,
          otherMsg: this.addForm.miaoshu,
          power: this.addForm.gonglv,
          productionDate: this.addForm.riqi,
          powerCategoryId: this.addForm.leixing,
          pic: this.imgUrl,
          powerCategoryName: energyName,
          projectId: Number(sessionStorage.getItem("projectId")),
          projectName: sessionStorage.getItem("projectName"),
        };
        if (this.editId == -1) {
          addEquip(param).then((res) => {
            if (res.data.code != 0) {
              this.$message.warning(res.data.msg);
            } else {
              this.addPop = false;
              this.getEquipList();
            }
          });
        } else {
          param.id = this.editId;
          editEquip(param).then((res) => {
            if (res.data.code != 0) {
              this.$message.warning(res.data.msg);
            } else {
              this.addPop = false;
              this.getEquipList();
            }
          });
        }
      }
    },
    //获取设备列表
    getEquipList() {
      this.loading = true;
      let param = {
        pageNum: this.pageInfo.pageNum,
        pageSize: this.pageInfo.pageSize,
        projectId: Number(sessionStorage.getItem("projectId")),
        projectName: sessionStorage.getItem("projectName"),
        tenantId: JSON.parse(sessionStorage.getItem("user")).tenantId,
      };
      equipList(param).then((res) => {
        if (res.data.code == 0) {
          this.pageInfo.total = res.data.total;
          this.tableData = res.data.rows;
        } else {
          this.$message.warning(res.data.msg);
        }
        this.loading = false;
      });
    },
    handleCurrentChange(val) {
      this.pageInfo.pageNum = val;
      this.getEquipList();
    },
    //删除具体设备
    deleteEquip(id) {
      let param = {
        ids: String(id),
      };
      delEquip(param).then((res) => {
        if (res.data.code == 0) {
          this.$message({
            type: "success",
            message: "删除成功!",
          });
        } else {
          this.$message.warning(res.data.msg);
          this.$message({
            type: "info",
            message: "已取消操作",
          });
        }
      });
    },
  },
};
</script>

<style scoped>
.pop .detail {
  padding-right: 20px;
}
::v-deep .el-tabs__item {
  font-size: 14px;
  color: rgba(255, 255, 255, 0.6);
  display: inline-block;
  vertical-align: middle;
  cursor: pointer;
}
::v-deep .el-tabs__item.is-active {
  font-size: 18px;
  font-weight: bold;
  color: #fff;
}
::v-deep .el-tabs__nav-scroll {
  height: 50px;
  display: flex;
  align-items: center;
  position: relative;
  background: url(../assets/images/icon_04.png) no-repeat left bottom;
  background-size: 196px 20px;
  padding-top: 10px;
}
.pop .el-date-editor.el-input {
  width: 100%;
}
.gauges-sec {
  padding: 0 60px;
}

.choose {
  width: 260px;
  margin-bottom: 25px;
}

.col {
  width: 30%;
  max-width: 387px;
}

.mod {
  margin-bottom: 25px;
}

.mod .smtit {
  font-size: 18px;
  text-align: center;
  width: 340px;
  line-height: 44px;
  margin: 0 auto;
  height: 44px;
  overflow: hidden;
  padding-top: 2px;
}

.mod .detail {
  padding: 25px 35px;
}

.mod .detail.bind {
  height: 300px;
  overflow-y: auto;
}

.drag-content {
  height: 240px;
}

.mod .detail.all {
  height: 600px;
  overflow-y: auto;
}

.mod1 {
  min-height: 300px;
  background: url(../assets/images/set_01.png) no-repeat center;
  background-size: 100% 100%;
}

.mod1 .smtit {
  background: url(../assets/images/set_05.png) no-repeat center;
  background-size: 100% 100%;
}

.mod1 >>> .el-select {
  display: block;
}

.mod1 >>> .el-form-item__label {
  float: none;
  text-align: left;
}

.mod2 {
  min-height: 348px;
  background: url(../assets/images/set_02.png) no-repeat center;
  background-size: 100% 100%;
}

.mod2 .smtit {
  background: url(../assets/images/set_06.png) no-repeat center;
  background-size: 100% 100%;
}

.mod3 {
  min-height: 378px;
  background: url(../assets/images/set_03.png) no-repeat center;
  background-size: 100% 100%;
}

.mod3 .smtit {
  background: url(../assets/images/set_07.png) no-repeat center;
  background-size: 100% 100%;
}

.mod3 .delete {
  background: url(../assets/images/set_10.png) no-repeat center;
  background-size: 100% 100%;
  width: 330px;
  height: 290px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.mod3 .delete .icon {
  width: 180px;
  background: url(../assets/images/set_10.png) no-repeat center;
  background-size: 100% 100%;
}

.mod4 {
  min-height: 658px;
  background: url(../assets/images/set_04.png) no-repeat center;
  background-size: 100% 100%;
}

.mod4 .smtit {
  background: url(../assets/images/set_08.png) no-repeat center;
  background-size: 100% 100%;
}

.mod4 >>> .el-input-group {
  background: rgba(26, 29, 50, 0.5);
  border: 1px solid #4a4f64;
  border-radius: 4px;
  overflow: hidden;
}

.mod4 >>> .el-input-group__append {
  background: transparent;
  border: none;
  color: #fff;
}

.mod4 >>> .el-input-group .el-input__inner {
  border: none !important;
  background: none !important;
}

.list .lk {
  display: flex;
  font-size: 16px;
  height: 52px;
  margin-top: 10px;
  margin-bottom: 10px;
  align-items: center;
  justify-content: center;
}

.list .lk:last-child {
  margin-bottom: 0;
}

.mod2 .list .lk {
  background-image: url(../assets/images/set_13.png);
}

.mod2 .list .lk:hover {
  color: #8e7ff6;
}

.mod4 .list .lk {
  background-image: url(../assets/images/set_12.png);
}

.mod4 .list .lk:hover {
  color: #71aef5;
}
::v-deep .el-tabs__nav-wrap::after {
  position: static !important;
}
::v-deep .el-tabs__active-bar {
  background-color: transparent;
}
::v-deep .disUoloadSty .el-upload--picture-card {
  display: none; /* 上传按钮隐藏 */
}
</style>
